import React, {FC, useContext, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import {Modal, Select} from '../../Components';
import Page from '../../Components/Page';
import {getUserFacingSampleType, technologies} from '../../helpers';
import {getDrugOptions, getReport, getSample, saveAnalysisDataNew} from '../../requests';
import {StateContext} from '../../state';
import {Option, TabItem, TechnologyData} from '../../types';

import './style.css';
import TestStrip from "./TestStrip";
import Ftir from "./Ftir";
import GCMSTest from "./GCMSTest";
import LCMSTest from "./LCMSTest";
import NMRTest from "./NMRTest";
import Icons from "../../Icons";


const ReportAnalysisData: FC = () => {
    const {dispatch} = useContext(StateContext);
    const {sample_id} = useParams<any>();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [sample, setSample] = useState<any>();
    const [hasError, setHasError] = useState<boolean>(true);
    const [currentIndex, setCurrentIndex] = useState<number>();
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
        useState<boolean | number>(false);
    const [technology, setTechnology] = useState<Option[]>([{id: 0, text: ""}]);
    const [technologyData, setTechnologies] = useState<TechnologyData[]>([{
        id: 1,
        hasError: true,
        technology: "",
        data: "",
    }]);
    const [drugItems, setDrugItems] = useState<Option[]>([]);

    let selected_tech_option: Option[] = []
    const loadOptions = async () => {
        const data = await getDrugOptions();
        const items = data.data.map((d: any) => ({
            id: d.id,
            text: d.name,
            report_to_public: d.report_to_public,
            alias: d.alias,
        }));
        setDrugItems(items);
    }
    useEffect(() => {
        setLoading(true);
        loadOptions().then(() => {
            getSample(sample_id).then(res => {
                const _sample = res.data.results[0]
                setSample(_sample);

                getReport(sample_id).then(res => {
                    const reports = res.data.map((rep: any, index: number) => {
                        const find_tech = technologies.find((item) => {
                            return item.id === rep.technology;
                        })
                        if (find_tech !== undefined) {
                            selected_tech_option.push(find_tech)
                        }

                        return {
                            id: index,
                            hasError: true,
                            technology: rep.technology,
                            data: {
                                drugs: rep.drugs_found,
                                techNotes: rep.tech_notes,
                                notes: rep.notes,
                                isLiquid: rep.is_liquid,
                                staffFirstName: rep.staff_first_name,
                                staffLastName: rep.staff_last_name,
                                stripResult: rep.strip_result,
                                stripType: rep.strip_type,
                                vialWeight: rep.weight
                            }
                        }

                    })
                    setTechnologies(reports)
                    setTechnology(selected_tech_option)
                }).catch(() => {
                })
            }).catch(() => {
                setLoading(false);
                history.goBack();
            }).finally(() => {
                setLoading(false);
            });
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sample_id, history])


    const submitHandler = (e: any) => {
        e.preventDefault();
        save();
    }

    const save = async (draft: boolean = false, next: any = null) => {
        try {
            setSubmitLoading(true);
            // const reportWeight = vialWeight ? parseFloat((vialWeight - sample.empty_weight).toFixed(4)) : undefined
            // await saveAnalysisData(sample_id, staffName, isLiquid === "Yes", reportWeight, noDrugs ? [] : drugs, notes, draft);
            await saveAnalysisDataNew(sample_id, technologyData, draft);
            if (next === null) {
                dispatch({
                    type: "showAlert",
                    payload: {
                        message: `${sample_id} Sample Status Updated`,
                        status: `Status: Results ${draft?"Drafted":"Reported"}`
                    }
                })
                history.replace(`/result/${sample_id}`, {tab: TabItem.SamplesToReport})
            } else {
                next();
            }
        } catch (err: any) {
            dispatch({
                type: "showAlert", payload: {
                    error: true,
                    message: err.message,
                }
            });
        } finally {
           setSubmitLoading(false);
        }
    }

    if (loading) {
        return (
            <Page full title="Report Analysis Data" sample_id={sample_id} onCancel={() => history.goBack()}>
                <h1 className="pal">Loading...</h1>
            </Page>
        )
    }
    const onModalClose = (value: boolean) => {
        setShowDeleteConfirmationModal(value);
    };
    const IsDisabled: boolean = (loading);
    const setTechnologyData = (data: TechnologyData) => {

        const index = data.id as number

        let currentTech = technologyData.at(index)
        if (currentTech) {
            currentTech.data = data.data
            currentTech.hasError=data.hasError
            const newData = technologyData.map((aa) => {

                if (aa.id === currentTech?.id) {

                    return currentTech
                }
                return aa
            })
            let hasError=false
           newData.forEach((item)=>hasError = hasError || item.hasError)
            setHasError(hasError)

            setTechnologies(newData)

        }
    }
    const recheckError = (data: TechnologyData[]) => {


            let hasError=false
           data.forEach((item)=>hasError = hasError || item.hasError)
            setHasError(hasError)
    }
    return (
        <Page mainClass="reportdata" full title="Report Analysis Data" sample_id={sample_id}
              onCancel={() => history.goBack()}>
            <form className="receiptsamples">
                {showDeleteConfirmationModal && (
                    <Modal
                        onClose={() => onModalClose(false)}
                        title="Delete Analysis Results?"
                        ButtonNoTitle="Cancel"
                        ButtonYes={
                            <button
                                type="button"
                                className="btn btn1"
                                onClick={() => {
                                    const newTechnologyData = technologyData.filter((_, index) => index !== currentIndex);
                                    const newTechnology = technology.filter((_, index) => index !== currentIndex);
                                    setTechnologies(newTechnologyData);
                                    setTechnology(newTechnology);
                                    recheckError(newTechnologyData)
                                    onModalClose(false)
                                }}
                            >
                                Delete Results
                            </button>
                        }
                    >
                        <p className="text-body" style={{marginBottom: "3.75rem"}}>
                            Are you sure you want to delete this set of results? This action cannot be undone.
                        </p>
                    </Modal>
                )}
                <div className="field">
                    <h6>Collection Data</h6>
                    <div className="coldata">
                        <div style={{marginRight: '6.25rem'}}>
                            <div className="text">Sample ID:</div>
                            <div className="bold mb">{sample_id}</div>
                        </div>
                        <div>
                            <div className="text">Sample Type:</div>
                            <div className="bold mb">{getUserFacingSampleType(sample.sample_type)}</div>
                        </div>
                    </div>
                    <div className="text">Expected Drug(s):</div>
                    <div className="bold mb">
                        {sample.expected_drugs.map((ed: string, index: number) => {
                            return <div key={index}>{ed}</div>
                        })}
                    </div>
                </div>
                {technologyData.map((dataItem, index) => {
                    let objectItems = [
                        <hr className="long-margin"/>,
                        <div className={technology[0]?.text.length>0?"field":"field pb-3"}>
                            <div className="form-header">
                                <h6>Technology {index > 0 ? index + 1 : ""}</h6>
                                {index > 0 && <button
                                    type="button"
                                    disabled={loading}
                                    className="btn btn2 mr-1"
                                    onClick={() => {
                                        setCurrentIndex(index)
                                        setShowDeleteConfirmationModal(true)
                                    }}
                                >
                                    <Icons.Trash style={{paddingRight: "6px"}}/>
                                    Delete Results
                                </button>}
                            </div>
                            <label className="label">Technology Used: <span className="required">*</span></label>
                            <Select placeholder="Select"
                                    items={technologies}
                                    onChange={(item: any) => {
                                        let newTechnology = technology;
                                        newTechnology[index] = item
                                        setTechnology(newTechnology)
                                        let currentTech = technologyData.at(index)

                                        if (currentTech) {
                                            currentTech.technology = item.id
                                            const newData = technologyData.map((aa) => {
                                                if (aa.id === currentTech?.id) {
                                                    return currentTech
                                                }
                                                return aa
                                            })
                                            setTechnologies(newData)

                                        }

                                    }}
                                    value={technology[index]}
                                    mainClass="mini"
                                    searchable={false}
                                    required
                            />
                        </div>]
                    switch (dataItem.technology) {
                        case 'TEST_STRIP':
                            objectItems.push(<TestStrip data={technologyData[index].data} sample={sample} index={index}
                                                        dataStateChanger={setTechnologyData}
                                                      />);
                            break;
                        case 'FTIR':
                            objectItems.push(<Ftir drugItems={drugItems} data={technologyData[index].data} index={index}
                                                   dataStateChanger={setTechnologyData}
                                                   sample={sample}/>);
                            break;
                        case 'GC_MS':
                            objectItems.push(<GCMSTest drugItems={drugItems} data={technologyData[index].data}
                                                       index={index}
                                                       dataStateChanger={setTechnologyData}
                                                       sample={sample}/>);
                            break;
                        case 'LC_MS':
                            objectItems.push(<LCMSTest drugItems={drugItems} data={technologyData[index].data}
                                                       index={index}
                                                       dataStateChanger={setTechnologyData}
                                                       sample={sample}/>);
                            break;
                        case 'NMR':
                            objectItems.push(<NMRTest drugItems={drugItems} data={technologyData[index].data}
                                                      index={index}
                                                      dataStateChanger={setTechnologyData}
                                                      sample={sample}/>);
                            break;

                    }
                    return objectItems
                })}

                {(technologyData.length > 0 && technologyData[0].technology !== '') && <>
                    <hr className="long-margin"/>
                    <div className="add-more">
                        <button
                            type="button"
                            disabled={loading}
                            className="btn btn4 mr-1"
                            onClick={() => {
                                const newTechnologyData = [...technologyData, {
                                    technology: "",
                                    hasError:true,
                                    data: {},
                                    id: technologyData.length + 1
                                }];
                                const newTechnology = [...technology, {text: "", id: ''}];
                                setTechnologies(newTechnologyData);
                                setTechnology(newTechnology);
                            }}
                        >
                            + Add More Results
                        </button>
                    </div>
                </>
                }

                <div className="action-bar">
                    <div className="field buttons" style={{justifyContent: 'flex-start'}}>
                        <button type="button"
                                disabled={loading || submitLoading}
                                className="btn btn2 mr-1"
                                onClick={() => save(true, () => {
                                    history.push(`/prepare-for-transport`, {
                                        checked_samples: [sample],
                                        sample: sample_id,
                                    })
                                })}>Request Further Analysis
                        </button>
                        <button type="button" disabled={loading || submitLoading} className="btn btn2 mr-1"
                                onClick={() => save(true)}>Save As Draft
                        </button>
                        <button type="button" disabled={IsDisabled || hasError || submitLoading} onClick={submitHandler}
                                className="btn btn1">Submit To Collection Site
                        </button>
                    </div>
                </div>

            </form>
        </Page>
    );
}

export default ReportAnalysisData;
