import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import PrepareTransport from '.';

import Page from '../../Components/Page';
import {StateContext} from '../../state';
import {TabItem} from '../../types';
import SampleMissing from '../ConfirmReceiptSamples/SampleMissing';
import {getPerms} from '../../requests'

type ILocation = {
    state: any;
    checked_samples: any[];
    sample?: string;
    samples?: any[];
}

const PrepareTransportRoute: FC = () => {
    const {dispatch} = useContext(StateContext);
    const [title, setTitle] = useState<string>('Prepare Samples For Transport');
    const [step, setStep] = useState<number>(-1);
    const [confirmedSamples, setConfirmedSample] = useState<any[]>([]);
    const [missingSamples, setMissingSamples] = useState<any[]>([]);
    const [fullname, setFullname] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const {state} = useLocation<ILocation>();
    const topRef = useRef<any>();

    useEffect(() => {
        if (!state) {
            history.replace("/", {tab: TabItem.SamplesToTransport});
            return;
        }
        setConfirmedSample(state.checked_samples);
        setMissingSamples([]);
    }, [state, history])

    const finish = (count: { intransit: number, missed: number }) => {
        if (count.intransit > 0) {
            dispatch({
                type: "showAlert", payload: {
                    message: `${count.intransit} Sample Statuses Updated`,
                    status: "Status: In Transit",
                }
            });
        }
        if (count.missed > 0) {
            dispatch({
                type: "showAlert", payload: {
                    message: `${count.missed} Sample Statuses Updated`,
                    status: "Status: Missing",
                }
            });
        }
        const {performs_collection} = getPerms();
        history.replace("/", {tab: performs_collection ? TabItem.SamplesToTransport : TabItem.SamplesToReport});
    }

    const confirmSamples = (checkedSamples: any[], _fullname?: string) => {
        const checkedSampleIDs: string[] = checkedSamples.map(cs => cs.sample_id);
        let missedSamples = confirmedSamples.filter(cs => !checkedSampleIDs.includes(cs.sample_id))
        if (state.samples) {
            const missedSampleIDs = missedSamples.map(cs => cs.sample_id);
            const usedIDs = [...checkedSampleIDs, ...missedSampleIDs];
            const unselectedIDs = state.samples.filter(cs => !usedIDs.includes(cs.sample_id) && ["IN_TRANSPORT", "FILLED"].includes(cs.state));
            missedSamples = [...missedSamples, ...unselectedIDs];
        }
        if (missedSamples.length > 0) {
            setMissingSamples(missedSamples);
            setTitle('Samples Missing?');
            setFullname(_fullname);
            setStep(checkedSampleIDs.length);
            if (topRef && topRef.current) {
                topRef.current.scrollIntoView();
            }
        } else {
            finish({intransit: checkedSamples.length, missed: 0});
        }
    }

    let onCancel = undefined;
    if (!loading) {
        onCancel = () => history.replace("/", {tab: TabItem.SamplesToTransport})

        if (state.sample) {
            onCancel = () => history.replace(`/result/${state.sample}`)
        }
    }

    return (
        <Page full title={title} onCancel={onCancel}>
            <div ref={topRef}></div>
            {step === -1 &&
                <PrepareTransport
                    checkedSamples={confirmedSamples}
                    onDone={confirmSamples}
                    onLoading={(status: boolean) => setLoading(status)}
                    sample={state.sample}
                />
            }
            {step >= 0 &&
                <SampleMissing
                    missingSamples={missingSamples}
                    receiving={false}
                    onDone={(count: number) => finish({missed: count, intransit: step})}
                    onLoading={(status: boolean) => setLoading(status)}
                    fullname={fullname}
                />
            }
        </Page>
    );
}

export default PrepareTransportRoute;
