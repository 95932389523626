import React, {FC, useCallback, useContext, useEffect, useState} from 'react';

import {Checkbox, Fullname, Select} from '../../Components';
import {Option} from '../../types';

import './style.css';
import Icons from "../../Icons";
import {useHistory, useParams} from "react-router-dom";
import {StateContext} from "../../state";

const TABLE_ROWS = 15;
type IProps = {
    sample: any;
    index: number;
    data: any;
    dataStateChanger: Function;
    drugItems: Option[];

}
const LCMSTest: FC<IProps> = (props: IProps) => {
    const sample = props.sample
    const history = useHistory();
    const {dispatch} = useContext(StateContext);
    const {sample_id} = useParams<any>();
    const [name, setName] = useState<any>({firstName: '', lastName: ""});

    const [loading, setLoading] = useState<boolean>(true);

    const [staffFirstName, setStaffFirstName] = useState<string>('');
    const [staffLastName, setStaffLastName] = useState<string>('');
    const [isLiquid, setIsLiquid] = useState<string>('');
    const [vialWeight, setVialWeight] = useState<number | undefined>(undefined);
    const [drugs, setDrugs] = useState<any[]>([]);
    const [notes, setNotes] = useState<string>('');
    const [techNotes, setTechNotes] = useState<string>('');
    const [noDrugs, setNoDrugs] = useState<boolean>(false);

    // Computed values. Could be memoized if there is a performance gain needed.
    const percentSum: number = drugs.reduce((sum, curDrug) => sum + parseFloat(curDrug?.percent ?? 0), 0);
    const weightSum: number = drugs.reduce((sum, curDrug) => sum + parseFloat(curDrug?.weight ?? 0), 0);
    const sampleWeight = (vialWeight !== undefined && vialWeight >= 0) ? parseFloat(((vialWeight - sample.empty_weight) * 1000).toFixed(4)) : "";
    const invalidPercentSum = percentSum > 100;
    const invalidWeightSum = !!weightSum && (!sampleWeight || weightSum > sampleWeight);


    const fillDrugs = (_drugs: any[] = []) => {
        const elements = _drugs.length;
        if (elements <= TABLE_ROWS) {
            for (let i = elements; i < TABLE_ROWS; i++) {
                _drugs.push({})
            }
            setDrugs(_drugs);
            setLoading(false)
        }
    }

    useEffect(() => {
        setNoDrugs(drugs.filter(d => d.name).length === 0);
    }, [drugs])

    useEffect(() => {

        fillDrugs();

    }, [sample_id, history])

    useEffect(() => {
        if (invalidPercentSum) {
            dispatch({
                type: "showAlert", payload: {
                    error: true,
                    message: 'Sum of drug percentages cannot exceed 100.',
                }
            });
        }
    }, [invalidPercentSum, dispatch])

    useEffect(() => {
        if (invalidWeightSum) {
            dispatch({
                type: "showAlert", payload: {
                    error: true,
                    message: 'Sum of quantities cannot exceed the sample weight.',
                }
            });
        }
    }, [invalidWeightSum, dispatch])

    const getValue = useCallback((index: number, key: string) => {
        const drug = drugs[index];
        if (drug) {
            const value = drug[key];
            if (value) {
                return value;
            }
        }
        return "";
    }, [drugs])

    const selectChange = (v: any, index: number) => {
        setDrugs((old: any) => {
            if (!v) {
                old[index] = {}
                return [...old];
            }
            const x = old.find((d: any) => d.item === v);
            if (!x) {
                old[index].item = v;
                old[index].name = v ? v.text : '';
                setNoDrugs(false);
            }
            return [...old];
        })
    }

    const handlePaste = (event: any, index: number, setIsOpen: any) => {
        event.preventDefault();
        setIsOpen && setIsOpen(false);

        const paste: string = event.clipboardData.getData('text');
        const lines: string[] = paste.split("\n");

        setDrugs((old: any) => {
            for (let i = 0; i < lines.length; i++) {
                const idx = index + i;
                const line = lines[i];
                const cells = line.split("\t").map(cell => cell.trim());

                const searchKey = cells[0].toLowerCase().trim();
                if (searchKey.length < 1) continue

                let hasDrug = props.drugItems.find((d: any) => d.text.toLowerCase().trim() === searchKey)
                if (!hasDrug) {
                    hasDrug = props.drugItems.find((d: any) => d.alias?.toLowerCase().trim().split(";").map((a: string) => a.trim()).includes(searchKey))
                }

                if (hasDrug) {
                    selectChange(hasDrug, idx)
                } else {
                    old[idx].item = {id: -1, text: cells[0]};
                    old[idx].name = cells[0];
                }

                if (cells.length > 1) {
                    const value = parseFloat(cells[1]);
                    if (!isNaN(value)) {
                        old[idx].percent = value.toFixed(2);
                    }
                }
                if (cells.length > 2) {
                    const value = parseFloat(cells[2]);
                    if (!isNaN(value)) {
                        old[idx].weight = value.toFixed(4);
                    }
                }
            }
            return [...old];
        })
    }

    const doPaste = (event: any, key: string, drug: any, index: number) => {
        event.preventDefault();
        const paste: string = event.clipboardData.getData('text');
        const lines: string[] = paste.split("\n");

        setDrugs((old: any) => {
            for (let i = 0; i < lines.length; i++) {
                const idx = index + i;
                const line = lines[i];
                const cells = line.split("\t").map(cell => cell.trim());

                if (cells.length >= 1) {
                    const value = parseFloat(cells[0]);
                    if (!isNaN(value)) {
                        old[idx][key] = value.toFixed(key === "percent" ? 2 : 4);
                    }
                }

                if (cells.length >= 2 && key === "percent") {
                    const value = parseFloat(cells[1]);
                    if (!isNaN(value)) {
                        old[idx].weight = value.toFixed(4);
                    }
                }
            }
            return [...old];
        })
    }

    const fixDecimal = (key: string, index: number, fractionDigits: number = 2) => {
        setDrugs((old: any) => {
            if (old[index][key]) {
                old[index][key] = parseFloat(old[index][key]).toFixed(fractionDigits);
            }
            return [...old];
        })
    }
    useEffect(() => {
        const staffIsEmpty = (staffFirstName.length + staffLastName.length) === 0

        const drugsIsInvalid = drugs.some(d => ((!d.name && d.percent) || (d.name && !d.percent))) ||
            (sample.sample_type !== "PARAPHERNALIA" && (
                !isLiquid ||
                isLiquid === "" ||
                (isLiquid === "No" && (vialWeight === undefined || vialWeight < 0))
            )) ||
            invalidPercentSum ||
            invalidWeightSum


        const hasError = staffIsEmpty || drugsIsInvalid
        const reportWeight = vialWeight ? parseFloat((vialWeight - sample.empty_weight).toFixed(4)) : undefined

        const data = {
            hasError,
            id: props.index,
            technology: "LC_MS",
            data: {
                drugs,
                notes,
                techNotes,
                noDrugs,
                isLiquid,
                vialWeight: reportWeight,
                staffLastName,
                staffFirstName
            }

        }
        props.dataStateChanger(data)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staffFirstName, staffFirstName, drugs, noDrugs, techNotes, notes, isLiquid,
        vialWeight, invalidWeightSum, invalidPercentSum, sample.sample_type]);

    useEffect(() => {
        setNotes(props.data.notes);
        setTechNotes(props.data.techNotes);
        setIsLiquid(props.data.isLiquid ? "Yes" : "No")
        if (props.data.vialWeight) {
            setVialWeight(+parseFloat(sample.empty_weight + props.data.vialWeight).toFixed(4))

        }
        const name = {firstName: props.data?.staffFirstName ?? "", lastName: props.data?.staffLastName ?? ""}

        setName(name)
        const draftDrugs: any[] = [];
        props.data?.drugs?.forEach((d: any, index: number) => {
            draftDrugs[index] = {
                item: d.drug ? {...d.drug, text: d.drug.name} : {id: -1, text: d.custom_drug},
                name: d.drug ? d.drug.name : d.custom_drug,
                weight: d.weight,
                percent: d.percent,
            }
        })

        fillDrugs(draftDrugs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <>


            {sample.sample_type === "SUBSTANCE" &&
                <>
                    <div className="field">
                        <h6>Drug Sample Details</h6>
                    </div>
                    <div className="field">
                        <label className="label">Is this drug a liquid? <span
                            className="required">*</span></label>
                        <Select
                            items={[{id: 0, text: 'No'}, {id: 1, text: 'Yes'}]}
                            onChange={(item: any) => {
                                if (item) {
                                    setIsLiquid(item.text)
                                } else {
                                    setIsLiquid("");
                                }
                            }}
                            value={isLiquid ? {
                                id: isLiquid === "Yes" ? 1 : 0,
                                text: isLiquid === "Yes" ? 'Yes' : 'No'
                            } : undefined}
                            mainClass="mini"
                            searchable={false}
                            required
                        />
                    </div>
                    <div className="field">
                        <div className={`details w80 ${isLiquid !== "No" ? 'liquid' : ''}`}>
                            <div className="winp">
                                <label>Vial weight (with sample):</label>
                                <div className="input">
                                    <input
                                        type="number"
                                        step="0.0001"
                                        disabled={loading || isLiquid !== "No"}
                                        value={vialWeight}
                                        onWheel={(e: any) => e.target.blur()}
                                        onChange={e => {
                                            if (!isNaN(parseFloat(e.target.value))) {
                                                setVialWeight(+parseFloat(e.target.value).toFixed(4))
                                            } else {
                                                setVialWeight(undefined);
                                            }
                                        }}
                                    />
                                    <p>g</p>
                                </div>
                            </div>
                            <div className="winp">
                                <label>Vial weight (empty):</label>
                                <div className="input">
                                    <div className="sign signleft">-</div>
                                    <input
                                        type="number"
                                        step="0.0001"
                                        readOnly
                                        value={parseFloat((sample.empty_weight).toFixed(4))}
                                    />
                                    <p>g</p>
                                    <div className="sign signright">=</div>
                                </div>
                            </div>
                            <div className="winp">
                                <label>Sample Weight:</label>
                                <div className="input">
                                    <input
                                        type="number"
                                        step="0.0001"
                                        readOnly
                                        value={sampleWeight}
                                    />
                                    <p>mg</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </>
            }
            <div className="field">
                <h6>Substances Found In Sample</h6>
                <label className="label">Paste or enter into the fields below.</label>
                <div className=" mb-2">
                    <table className="drugfoundtable">
                        <thead>
                        <tr style={{height: '47px'}}>
                            <th style={{width: '10px'}}></th>
                            <th style={{width: '29%'}}>Substance Name</th>
                            <th style={{width: '15%'}}>Relative %</th>
                            <th style={{width: '17%'}}>Quantity (mg)</th>
                            <th style={{width: '8%'}}>Public</th>
                            <th>Alias</th>
                        </tr>
                        </thead>
                        <tbody>
                        {drugs.map((drug: any, index: number) => {
                            return (
                                <tr key={index}>
                                    <td className="rowindex">{index + 1}</td>
                                    <td className="entry">
                                        <Select
                                            items={props.drugItems}
                                            onChange={(v: any) => selectChange(v, index)}
                                            value={drug.item}
                                            mainClass="whiteback"
                                            nochevron
                                            canAdd
                                            onPaste={(event: any, setIsOpen: any) => handlePaste(event, index, setIsOpen)}
                                        />
                                    </td>
                                    <td className="entry input">
                                        <input type="number"
                                               step="0.001"
                                               max="100"
                                               className="entryinput"
                                               onWheel={(e: any) => e.target.blur()}
                                               onChange={e => {
                                                   setDrugs((old: any) => {
                                                       old[index].percent = e.target.value;
                                                       return [...old];
                                                   });
                                               }}
                                               value={noDrugs ? "" : getValue(index, 'percent')}
                                               onBlur={() => fixDecimal("percent", index)}
                                               onPaste={event => doPaste(event, 'percent', drug, index)}
                                        />
                                    </td>
                                    <td className="entry input">
                                        <input type="number"
                                               step="0.0001"
                                               className="entryinput"
                                               onWheel={(e: any) => e.target.blur()}
                                               onChange={e => {
                                                   setDrugs((old: any) => {
                                                       old[index].weight = e.target.value;
                                                       return [...old];
                                                   })
                                               }}
                                               value={noDrugs ? "" : getValue(index, 'weight')}
                                               onBlur={() => fixDecimal("weight", index, 4)}
                                               onPaste={event => doPaste(event, 'weight', drug, index)}
                                        />
                                    </td>
                                    <td>
                                        {(drug.item && (drug.item.id === -1 || drug.item.report_to_public)) &&
                                            <Icons.IsPublic/>
                                        }
                                    </td>
                                    <td>
                                        {drug.item ? drug.item.alias ? drug.item.alias : "---" : ""}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <div style={{width: '250px'}}>
                    <Checkbox
                        checked={noDrugs}
                        onChange={(v: boolean) => {
                            if (v) {
                                fillDrugs([]);
                            }
                            setNoDrugs(v);
                        }}
                        label="No Substances Found"
                    />
                </div>
            </div>

            <div className="field w80">
                <label className="label">Tech Notes: (optional)</label>
                <textarea

                    style={{width: '100%'}}
                    value={techNotes}
                    onChange={e => setTechNotes(e.target.value)}
                />
            </div>
            <div className="field w80">
                <label className="label">Public Notes (optional):</label>
                <textarea
                    disabled={loading}
                    style={{width: '100%'}}
                    value={notes}
                    onChange={e => setNotes(e.target.value)}
                />
            </div>


            <div className="field w80">
                <label className="label">
                    Full name of staff that analyzed this sample using this technology: <span
                    className="required">*</span>
                </label>
                <Fullname name={name}
                          onName={(firstName: string, lastName: string) => {setStaffFirstName(firstName);setStaffLastName(lastName)}}
                          onChange={(fullname: string) => {
                          }}/>
            </div>

        </>


    );
}

export default LCMSTest;
