import React, { FC, useContext, useState, useCallback, useEffect } from 'react';
import Icons from '../../Icons';
import { StateContext } from '../../state';
import './style.css';

const Notifications: FC = () => {
    const { state, dispatch } = useContext(StateContext);
    const [hiding, setHiding] = useState<number[]>([]);

    const hideAlert = useCallback((id: number) => {
        setHiding((prev) => [...prev, id]); // Add the ID to hiding list
        setTimeout(() => {
            dispatch({ type: 'removeAlert', id }); // Remove the alert after transition
        }, 500); // Transition duration
    }, [dispatch]);

    useEffect(() => {
        const timers = state.alerts.map((alert: { id: number; }) =>
            setTimeout(() => hideAlert(alert.id), 3000) // Auto-hide after 3 seconds
        );
        return () => timers.forEach(clearTimeout); // Clear timers on unmount
    }, [state.alerts, hideAlert]);

    return (
        <div className="notifications-container">
            {state.alerts.map((alert: { error: any; id: number; view: () => void; message: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; status: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => {
                const Icon = alert.error ? Icons.Alert : Icons.Check;

                return (
                    <div
                        key={alert.id}
                        style={{ backgroundColor: alert.error ? '#C33F38' : '#1E9E09' }}
                        className={`notification ${hiding.includes(alert.id) ? 'hide' : 'show'}`}
                        onClick={() => {
                            if (alert.view) alert.view();
                            hideAlert(alert.id);
                        }}
                    >
                        <Icon className="icon" />
                        <div className="content">
                            <div className="message">{alert.message}</div>
                            {alert.status && <div className="status">{alert.status}</div>}
                        </div>
                        {alert.view && (
                            <div
                                className="view"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    alert.view();
                                    hideAlert(alert.id);
                                }}
                            >
                                View
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default Notifications;
