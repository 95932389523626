import React, {FC, useState, useContext} from 'react';

import Icons from '../../Icons';
import {saveCollection} from '../../requests';
import { StateContext } from '../../state';

type IProps = {
    form: any;
    onEdit: () => void;
    onNext: () => void;
    onDone: () => void;
    onResult: (data: any) => void;
    permissions: {
        isStaffFrom: boolean,
        isServiceUser: boolean,
        isStaffUser: boolean
    }
}

const Review: FC<IProps> = (props: IProps) => {
    const {dispatch} = useContext(StateContext);
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = async () => {
        try {
            setLoading(true);
            const {data} = await saveCollection(props.form);

            props.onResult(data);
            props.onNext();
        } catch (err: any) {
            dispatch({type: "showAlert", payload: {
                error: true,
                message: err.message,
            }});
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <p className="reviewAlert">
                <Icons.ReviewAlert />
                Please review your responses and submit the collection survey. Prior to submitting, you can edit your responses if you need to.
            </p>
            <hr className="full full1" />
            <div className="field">
                <div className="text">Full name of staff handling this sample:</div>
                <div className="bold mb">{ props.form.fullname }</div>
            </div>
            {props.form.onsite_sample_id && <div className="field">
                <div className="text">Onsite Sample ID:</div>
                <div className="bold mb">{props.form.onsite_sample_id}</div>
            </div>}
            <div className="field">
                <div className="text">Results are for:</div>
                <div className="bold mb">{props.form.collected_from.text}</div>
            </div>
            {props.permissions.isStaffFrom &&
                <div className="field">
                    <div className="text">Name of agency this sample is coming from:</div>
                    <div className="bold mb">{ props.form.agency }</div>
                </div>
            }
            {props.form.used_before &&
                <div className="field">
                    <div className="text">Have you used this drug checking service before?</div>
                    <div className="bold mb">{ props.form.used_before.text }</div>
                </div>
            }
            {props.form.used_before && props.form.used_before.id === 1 &&
                <div className="field">
                    <div className="text">Have you previously accessed harm reduction services, such as drug checking or supervised consumption?</div>
                    <div className="bold mb">{ props.form.accessed_before ? props.form.accessed_before.text : '-' }</div>
                </div>
            }
            <div className="field">
                <div className="text">Sample type:</div>
                <div className="bold mb">{props.form.sample_type.text}</div>
            </div>
            {props.permissions.isServiceUser && props.form.sample_type.text === "Drug (vial)" &&
                <div className="field">
                    <div className="text">Did you use this drug prior to submitting this sample?</div>
                    <div className="bold mb">{props.form.already_used ? props.form.already_used.text : '-'}</div>
                </div>
            }
            {props.form.sample_type && props.form.sample_type.text.toLowerCase() === "used equipment (baggie)" &&
                <div className="field">
                    <div className="text">Has this equipment been reused?</div>
                    <div className="bold mb">{props.form.paraphernalia_reuse ? props.form.paraphernalia_reuse.text : '-'}</div>
                </div>
            }
            <div className="field">
                <div className="text">Sample ID:</div>
                <div className="bold mb">{props.form.sample_id.text}</div>
            </div>
            <div className="field">
                <div className="text">What was the sample purchased as?</div>
                <div className="bold mb">{props.form.expected_drugs && props.form.expected_drugs.map((ed: any)=> {
                    return <div key={ed.id}>{ed.other && ed.main+": "}{ed.text}</div>
                })}</div>
            </div>

            <div className="field">
                <div className="text">Was this sample associated with an overdose or other unpleasant or abnormal effects?</div>
                <div className="bold mb">{(props.form.sample_effects && props.form.sample_effects.length > 0) ? props.form.sample_effects.map((ed: any, index: number)=> {
                    return <div key={index}>{ed.text}</div>
                }) : '-'}</div>
            </div>
            {props.permissions.isServiceUser &&
                <div className="field">
                    <div className="text">Why are you checking this sample?</div>
                    <div className="bold mb">{(props.form.checking_purpose && props.form.checking_purpose.length > 0) ? props.form.checking_purpose.map((ed: any, index: number)=> {
                        return <div key={index}>{ed.text}</div>
                    }) : '-'}</div>
                </div>
            }  
            {props.permissions.isServiceUser &&
                <div className="field">
                    <div className="text">Are you planning to wait for your drug checking results before using this drug, using it again, or selling it?</div>
                    <div className="bold mb">
                        {props.form.wait_for_results && <>{props.form.wait_for_results.text}</>}
                        {!props.form.wait_for_results && <>-</>}
                    </div>
                </div>
            }  
            <div className="field">
                <div className="text">Sample colour:</div>
                <div className="bold mb">{(props.form.sample_colours && props.form.sample_colours.length > 0) ? props.form.sample_colours.map((ed: any, index: number)=> {
                    return <div key={index}>{ed.text}</div>
                }) : '-'}</div>
            </div>
            <div className="field">
                <div className="text">Sample texture:</div>
                <div className="bold mb">{(props.form.sample_textures && props.form.sample_textures.length > 0) ? props.form.sample_textures.map((ed: any, index: number)=> {
                    return <div key={index}>{ed.text}</div>
                }) : '-'}</div>
            </div>
            {props.form.results_provision && !props.permissions.isStaffUser &&
                <div className="field">
                    <div className="text">How do you wish to receive your results?</div>
                    <div className="bold mb">{props.form.results_provision.text}</div>
                </div> 
            }
            <div className="pb-8"></div>
            <div className="review banner">
                <div className="bannercontent">
                    <button disabled={loading} className="btn btn2 mr-1" onClick={() => props.onEdit()}><Icons.Edit style={{marginRight: '4px'}} /> Edit</button>
                    <button disabled={loading} className="btn btn1" onClick={onSubmit}>Submit Collection Survey</button>
                </div>
            </div>
        </>
    )
}

export default Review;
