import React, {FC, useEffect, useState} from 'react';

import {Fullname, Select} from '../../Components';
import {stripResults} from '../../helpers';
import {Option} from '../../types';

import './style.css';

type IProps = {
    sample: any;
    index: number;
    dataStateChanger: Function;
    data: any
}

const TestStrip: FC<IProps> = (props: IProps) => {

    const [stripResult, setResult] = useState<Option>();
    const [staffFirstName, setStaffFirstName] = useState<string>('');
    const [staffLastName, setStaffLastName] = useState<string>('');

    const [type, setType] = useState<string>('');
    const [notes, setNotes] = useState<string>('');
    const [isDirty, setDirty] = useState<boolean>(false);
    const [name, setName] = useState<any>({firstName: '', lastName: ""});

    useEffect(() => {
        const staffIsEmpty = (staffFirstName.length + staffLastName.length) === 0
        const typeEmpty = type.length === 0
        const resultEmpty = stripResult?.text?.length === 0
        const hasError = staffIsEmpty || typeEmpty || resultEmpty
        const data = {
            hasError,
            id: props.index,
            technology: "TEST_STRIP",
            data: {
                staffFirstName, staffLastName, stripResult, type, techNotes: notes
            }

        }
        setDirty(true)
        props.dataStateChanger(data)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staffLastName, staffFirstName, stripResult, type, notes]);
    useEffect(() => {
        if (!isDirty) {
            const name = {firstName: props.data?.staffFirstName ?? "", lastName: props.data?.staffLastName ?? ""}
            setName(name)
            setType(props.data.stripType ?? "")
            const selectedResult = stripResults.find((item) => item.id === props.data.stripResult)
            setResult(selectedResult)
            setNotes(props.data.techNotes)
        }
    }, [props.data, isDirty]);
    return (

        <>
            <div className="field">
                <label className="label">Test Strip Type: <span className="required">*</span></label>
                <input
                    type="text"
                    value={type}
                    onChange={e => {
                        setType(e.target.value)
                    }}
                    required
                />
            </div>
            <div className="field">
                <label className="label">Test Strip Result: <span className="required">*</span></label>
                <Select
                    placeholder="Select"
                    items={stripResults}
                    onChange={(item: any) => {
                        if (item) {
                            setResult(item)
                        } else {
                            setResult({id: "", text: ""});
                        }
                    }}
                    value={stripResult}
                    mainClass="mini"
                    searchable={false}
                    required
                />
            </div>

            <div className="field w80">
                <label className="label">Tech Notes: (optional)</label>
                <textarea

                    style={{width: '100%'}}
                    value={notes}
                    onChange={e => setNotes(e.target.value)}
                />
            </div>
            <div className="field w80">
                <label className="label">
                    Full name of staff that analyzed this sample using this technology: <span
                    className="required">*</span>
                </label>
                <Fullname name={name} onName={(firstName: string, lastName: string) => {
                    setStaffFirstName(firstName)
                    setStaffLastName(lastName)
                }} onChange={(fullname: string) => {
                }}/>
            </div>
        </>


    );
}

export default TestStrip;
