import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import { useHistory, useLocation } from 'react-router';

import ConfirmReceiptSamples from '.';
import SampleMissing from './SampleMissing';
import Page from '../../Components/Page';
import { StateContext } from '../../state';
import { TabItem } from '../../types';

type ILocation = {
    state: any,
    checked_samples: any[]
    samples?: any[],
}

const ConfirmReceiptSamplesRoute: FC = () => {
    const {dispatch} = useContext(StateContext);
    const [title, setTitle] = useState<string>('Confirm Receipt Of Samples');
    const [step, setStep] = useState<number>(-1);
    const [confirmedSamples, setConfirmedSample] = useState<any[]>([]);
    const [missingSamples, setMissingSamples] = useState<any[]>([]);
    const [fullname, setFullname] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const {state} = useLocation<ILocation>();
    const topRef = useRef<any>();

    useEffect(() => {
        if (!state) {
            history.replace("/", {tab: TabItem.SamplesToReceive});
        } else {
            setConfirmedSample(state.checked_samples);
            setMissingSamples([]);
        }
    }, [state, history])

    const finish = (count: { undergoung: number, missed: number }) => {
        if (count.undergoung > 0) {
            dispatch({type: "showAlert", payload: {
                message: `${count.undergoung} Sample Statuses Updated`,
                status: "Status: Undergoing Analysis",
            }});
        }
        if (count.missed > 0) {
            dispatch({type: "showAlert", payload: {
                message: `${count.missed} Sample Statuses Updated`,
                status: "Status: Missing",
            }});
        }
        history.replace("/", {tab: TabItem.SamplesToReceive});
    }

    const confirmSamples = (checkedSamples: any[], _fullname: string | undefined) => {
        const checkedSampleIDs: string[] = checkedSamples.map(cs => cs.sample_id);
        let missedSamples = confirmedSamples.filter(cs => !checkedSampleIDs.includes(cs.sample_id))
        if (state.samples) {
            const missedSampleIDs = missedSamples.map(cs => cs.sample_id);
            const usedIDs = [...checkedSampleIDs, ...missedSampleIDs];
            const unselectedIDs = state.samples.filter(cs => !usedIDs.includes(cs.sample_id) && ["IN_TRANSPORT"].includes(cs.state));
            missedSamples = [...missedSamples, ...unselectedIDs];
        }
        if (missedSamples.length > 0) {
            setMissingSamples(missedSamples);
            setTitle('Samples Missing?');
            setStep(checkedSampleIDs.length);
            setFullname(_fullname)
            if (topRef && topRef.current) {
                topRef.current.scrollIntoView();
            }
        } else {
            finish({missed:0,undergoung:checkedSamples.length});
        }
    }

    let onCancel = undefined;
    if (!loading) {
        onCancel = () => history.replace("/", {tab: TabItem.SamplesToReceive})
    }

    return (
        <Page full title={title} onCancel={onCancel}>
            <div ref={topRef}></div>
            {step === -1 && 
                <ConfirmReceiptSamples
                    checkedSamples={confirmedSamples}
                    onDone={confirmSamples} 
                    onLoading={(status: boolean) => setLoading(status)}
                />
            }
            {step >= 0 && 
                <SampleMissing 
                    missingSamples={missingSamples}
                    receiving={true}
                    onDone={(count) => finish({missed:count,undergoung:step})}
                    onLoading={(status: boolean) => setLoading(status)}
                    fullname={fullname}
                />
            }
        </Page>
    );
}

export default ConfirmReceiptSamplesRoute;
