import React, {FC, useContext, useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router';

import { Checkbox, Fullname } from '../../Components';
import { getUserFacingSampleType } from '../../helpers';
import { disposeSamples } from '../../requests';
import { StateContext } from '../../state';
import { TabItem } from '../../types';

type ILocation = {
    state: any;
    checked_samples: any[];
    sample?: string;
}

const DisposeSample: FC = () => {
    const {dispatch} = useContext(StateContext);
    const {state} = useLocation<ILocation>();
    const history = useHistory();
    const [checkedSamples, setCheckedSamples] = useState<any[]>([]);
    const [confirmedSamples, setConfirmedSamples] = useState<any[]>([]);
    const [staffFullName, setStaffFullName] = useState<string>('');
    const [confirmDisposed, setConfirmDisposed] = useState<boolean>(false);
    const [witnessName, setWitnessName] = useState<string>('');
    const [confirmDestroyed, setConfirmDestroyed] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [why, setWhy] = useState<string>('');

    useEffect(() => {
        if (!state) {
            history.replace("/", {tab: TabItem.SamplesToDispose});
        } else {
            setCheckedSamples(state.checked_samples);
            setConfirmedSamples(state.checked_samples);
            
            if (state.sample) {
                setConfirmedSamples(state.checked_samples)
            }
        }
    }, [state, history])


    const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (confirmedSamples.length === 0) {
            return;
        }
        try {
            setLoading(true);
            await disposeSamples(confirmedSamples.map(cs => cs.sample_id), staffFullName, witnessName, why);
            dispatch({type: "showAlert", payload: {
                message: `${confirmedSamples.length} Sample Statuses Updated`,
                status: "Status: Disposed",
            }});
            history.replace("/", {tab: TabItem.SamplesToDispose});
        } catch (err: any) {
            dispatch({type: "showAlert", payload: {
                error: true,
                message: err.message,
            }});
        } finally {
            setLoading(false);
        }
    }
    const showWhy = state.sample !== undefined && (state.checked_samples[0].report_state!=="ANALYSIS_SHARED" &&
        state.checked_samples[0].report_state!=="ANALYSIS_COMPLETE"

    )
    const IsDisabled = loading || confirmedSamples.length === 0 || !staffFullName || 
            !confirmDisposed || !witnessName || !confirmDestroyed ||  (showWhy && !why);


    return (
        <form className="receiptsamples" onSubmit={submitHandler}>
            <div className="field">
                <div className="box CAMH">
                    {checkedSamples.map((sample: any, index: number) => {
                        return (
                            <div key={index} className="boxrow">
                                {!state.sample &&
                                    <Checkbox
                                        disabled={loading}
                                        checked={!!confirmedSamples.find(cs => cs.sample_id === sample.sample_id)}
                                        onChange={(s: boolean) => {
                                            const item = confirmedSamples.find(cs => cs.sample_id === sample.sample_id);
                                            if (item && !s) {
                                                setConfirmedSamples(os => os.filter(o => o.sample_id !== item.sample_id));
                                            } else if (!item && s) {
                                                setConfirmedSamples(os => [...os, sample]);
                                            }
                                        }} 
                                    />
                                }
                                <div className="boxsample" style={{marginRight: '4.75rem'}}>
                                    <span className="sampleidlabel">Sample ID:</span>
                                    <span className="sampleid">{sample.sample_id}</span>
                                </div>
                                <div className="boxsample">
                                    <span className="sampleidlabel">Sample type:</span>
                                    <span className="sampleid">{getUserFacingSampleType(sample.sample_type)}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {(showWhy) &&
                <div className="field">
                    <label className="label">
                        Why are you disposing this sample?
                        <span className="required">*</span>
                    </label>
                    <textarea onChange={e => setWhy(e.target.value)} value={why} required style={{width: '100%'}} />
                </div>
            }
            <div className="field">
                <label className="label">
                    Full name of staff that disposed of sample(s): <span className="required">*</span>
                </label>
                <Fullname onChange={(fullname: string) => setStaffFullName(fullname)} />
            </div>
            <div className="field">
                <Checkbox
                    disabled={loading}
                    required={true}
                    checked={confirmDisposed} 
                    onChange={(s: boolean) => setConfirmDisposed(s)}
                    label="I confirm that the sample(s) have been disposed of following exempted processes"
                />
            </div>
            <hr />
            <div className="field pt-1">
                <label className="label">
                    Full name of witness: <span className="required">*</span>
                </label>
                <Fullname onChange={(fullname: string) => setWitnessName(fullname)} />
            </div>
            <div className="field">
                <Checkbox
                    disabled={loading}
                    required={true}
                    checked={confirmDestroyed} 
                    onChange={(s: boolean) => setConfirmDestroyed(s)}
                    label="I confirm sample(s) were destroyed"
                />
            </div>
            <div className="field">
                <button disabled={IsDisabled} type="submit" className="btn btn1">Submit</button>
            </div>
        </form>
    );
}

export default DisposeSample;
