import React, {FC} from 'react';
import {DrugFound} from "../../types";
import {getPerms} from "../../requests";
import Preamble from "./preamble";
import Icons from "../../Icons";
import {displayFormula} from "../../helpers";


const Noteworthy: FC = () => {
    return <span className="noteworthy"><b>(!)</b></span>;
}

type IProps = {
    isDraft: boolean;
    drugs: any[];
    weight?: number;
    isLiquid: boolean;
    performsCollection: boolean;
    notes:string;
    technology:string;
}

const TestTechnology: FC<IProps> = (props: IProps) => {
    const drugs: any[] = props.performsCollection ? props.drugs.filter(d => d.custom_drug || d.drug?.report_to_public) : props.drugs
    const topDrugs = drugs.filter((drug: DrugFound) => drug.weight && drug.weight > 0 && (drug.drug?.report_to_public || drug.custom_drug));
    const bottomDrugs = props.isLiquid ? drugs : drugs.filter((drug: DrugFound) => !drug.weight && (drug.drug?.report_to_public || drug.custom_drug));

    topDrugs.sort((a, b) => b.weight - a.weight)
    bottomDrugs.sort((a, b) => b.percent - a.percent)

    let topDrugsWeight = 0;
    let topDrugsPercent = 0;
    topDrugs.forEach((td: any) => {
        topDrugsWeight += +td.weight;
        topDrugsPercent += +td.percent;
    })

    let bottomDrugsWeight = 0;
    bottomDrugs.forEach((bd: any) => {
        bottomDrugsWeight += +bd.weight
    })

    if (props.weight && topDrugsWeight > 0) {
        bottomDrugsWeight = +props.weight - topDrugsWeight;
    }

    const {performs_analysis} = getPerms();

    const getDrugName = (drug: any) => {
        if (drug.custom_drug) return drug.custom_drug
        if (performs_analysis) return drug.drug?.name
        if (drug.drug.report_to_client_name) return drug.drug.report_to_client_name
        return drug.drug?.name
    }

    const isSemiQuantified = (bottomDrugsWeight === 0 && topDrugsWeight === 0) || props.isLiquid
    const isQuantified = (bottomDrugsWeight > 0 || topDrugsWeight > 0) && !props.isLiquid

    let cannotIdentify = 100
    if (props.weight && topDrugs.length > 0) {
        const weight = +props.weight;
        topDrugs.forEach(d => {
            cannotIdentify -= parseFloat(((+d.weight / weight) * 100).toFixed(2))
        })
    }

    const noDrugsFound = drugs.length === 0 && !props.isDraft
    const oneDrugFound = drugs.length === 1 && isSemiQuantified
    const hasIndicator = drugs.length > 1 && bottomDrugs.length > 0

    return (
        <div>
            <hr/>
            {!performs_analysis && !noDrugsFound &&
                <Preamble
                    isQuantified={isQuantified}
                    isSemiQuantified={isSemiQuantified}
                    noDrugsFound={noDrugsFound}
                    oneDrugFound={oneDrugFound}
                    hasIndicator={hasIndicator}
                />
            }
            <section className="technology-section bordered">
                <div className="technology-title">
                    <div className="field">
                        <div className="text">Technology:</div>
                        <div className="bold mb">{props.technology}</div>
                    </div>

                    <div className="info-link">
                        <a href="https://drugchecking.community/resource/service-and-technology-limitations/"
                           target="_blank" rel="noreferrer">
                            Technology Limitations <Icons.ExternalLink style={{marginRight: "7px", padding: "3px"}}/>
                        </a>

                    </div>
                </div>
                <hr/>
                <div className="drugsfound">
                    <div className="drugs-header">

                        <div className="text">Substances Found:</div>

                        <div className="info-link">
                            <a href="https://drugchecking.community/drug-dictionary/"
                               target="_blank" rel="noreferrer">
                                Drug Dictionary <Icons.ExternalLink
                                style={{marginRight: "7px", padding: "3px"}}/>
                            </a>

                        </div>
                    </div>

                    <div>


                        {drugs.length === 0 && !props.isDraft &&
                            <div className="nodrugs">No substances were found in this sample. The could be
                                because:
                                (i)
                                no substances are present in your drug, (ii) no substances were in the sample
                                you
                                submitted to be checked as a result of the Chocolate Chip Cookie Effect, or
                                (iii) we
                                missed substances due to technological limitations.</div>
                        }
                        {oneDrugFound &&
                            <div className="nodrugs">{getDrugName(drugs[0])} {drugs[0].drug?.important && <Noteworthy />} was the only substance found.</div>
                        }
                        <div className="drugslist">
                            {!props.isLiquid && drugs.length > 0 && topDrugs.length > 0 && topDrugs.map((d: DrugFound, index: number) => {
                                return (
                                    <div className="drugrow" key={index}>
                                        <div>
                                            {displayFormula(d.weight && d.weight > 0 ? d.weight : undefined, props.weight && props.weight > 0 ? props.weight : undefined)}
                                        </div>
                                        <div>({d.weight && props.weight && <>{((+d.weight / +props.weight) * 100).toFixed(2)}%</>})</div>
                                        <div>
                                            <b>{getDrugName(d)} </b>{d.drug?.important && <Noteworthy/>}
                                        </div>
                                    </div>
                                );
                            })}
                            {!props.isLiquid && drugs.length > 1 && bottomDrugs.length > 0 && topDrugsPercent > 0 &&
                                <div className="drugrow martop">
                                    <div>
                                        {bottomDrugsWeight >= 0 && <>{displayFormula(bottomDrugsWeight, props.weight && props.weight > 0 ? props.weight : undefined)}</>}
                                    </div>
                                    <div>
                                        {props.weight && props.weight > 0 && <>({((bottomDrugsWeight / props.weight) * 100).toFixed(2)}%)</>}
                                    </div>
                                    <div className="no-bold">Remaining substances found include:</div>
                                </div>
                            }
                            {!props.isLiquid && isQuantified && drugs.length > 0 && bottomDrugs.length === 0 &&
                                <div className="drugrow martop">
                                    <div className="dfrow">
                                        {cannotIdentify > 0 &&
                                            <><span>{(cannotIdentify / 10).toFixed(2)}</span> <span
                                                className="mg">mg</span></>
                                        }
                                    </div>
                                    {cannotIdentify>0&&<>
                                    <div>({cannotIdentify.toFixed(2)}%)</div>
                                    <div className="no-bold">Remaining contains substances we cannot identify.
                                    </div>
                                    </>}

                                </div>
                            }
                            {hasIndicator &&
                                <div className="drugrow martop">

                                    <div className="list">
                                        {bottomDrugs.map((d: DrugFound, index: number) => {
                                            return (<div key={index}><b>{getDrugName(d)} </b>{d.drug?.important &&
                                                <Noteworthy/>}</div>)
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                </div>
                <hr className="section-devider"/>

                <div className="drugnotes">
                    {props.notes && <><div>Notes</div>
                        <p>{props.notes}</p></>
                }
                    <p>
                        <Noteworthy/> | Indicates this is a noteworthy drug. “Noteworthy drugs” are drugs that (i) are
                        linked to
                        overdose or other unpleasant or abnormal effects, (ii) are highly potent or related to highly
                        potent
                        drugs, or (iii) may not be desired by some clients.
                    </p>
                </div>
            </section>

        </div>
    );
}

export default TestTechnology;
