import React from 'react';
import { SortOrder, TabItem } from './types';

export const getUser = () => {
    let user = localStorage.getItem("user");
    if (!user) return undefined;
    return JSON.parse(user);
}

export const initialState = {
    user: getUser(),
    alerts: [],
    activeTab: getUser() === undefined ? undefined : getUser().performs_collection ? TabItem.SamplesToTransport : TabItem.SamplesToReceive,
    sort: {
        [TabItem.ResultsToShare]: {column: 'date_collected', order: SortOrder.DESC},
        [TabItem.AllSamplesCollection]: {column: 'date_collected', order: SortOrder.DESC},
        [TabItem.AllSamplesAnalysis]: {column: 'date_received', order: SortOrder.DESC},
        [TabItem.SamplesToReport]: {column: 'date_received', order: SortOrder.ASC},
        [TabItem.SamplesToDispose]: {column: 'date_received', order: SortOrder.ASC},
        [TabItem.SamplesToTransport]: {column: 'sample_id', order: SortOrder.ASC},
        [TabItem.SamplesToReceive]: {column: 'sample_id', order: SortOrder.ASC},
    },
    sortLoading: false,
};

export const reducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case 'login':
            localStorage.setItem("user", JSON.stringify(action.payload));
            return {...state, user: {...action.payload}, activeTab: action.payload.performs_collection ? TabItem.SamplesToTransport : TabItem.SamplesToReceive};
        case 'logout':
            localStorage.removeItem("user");
            return {...state, user: undefined};
        case 'refresh':
            const user = getUser()
            user.access = action.payload;
            localStorage.setItem("user", JSON.stringify(user));
            return {...state, user: {...state.user, access: action.payload}}
        case 'showAlert':
        case 'addAlert':
            return {
                ...state,
                alerts: [...state.alerts, { id: Date.now(), ...action.payload }],
            };
        case 'removeAlert':
            return {
                ...state,
                alerts: state.alerts.filter((alert: { id: any; }) => alert.id !== action.id),
            };
        case 'alertShown':
            return {...state, alert: null};
        case 'setSort':
            const {column, order} = action.payload
            return {...state, sort: {...state.sort, [state.activeTab]: {column, order}}}
        case 'toggleSortLoading':
            return {...state, sortLoading: action.payload}
        case 'setActiveTab':
            return {...state, activeTab: action.payload}
        default:
            return {...state};
    }
}

type ContextType = {
    state: any;
    dispatch: any;
}

export const StateContext = React.createContext<ContextType>({
    state: initialState,
    dispatch: (param: { type: string; payload?: any }) => {},
});