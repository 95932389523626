import React, {FC, useCallback, useContext, useEffect, useState} from 'react';

import {Checkbox, Fullname, Select} from '../../Components';
import {Option} from '../../types';

import './style.css';
import Icons from "../../Icons";
import {useHistory, useParams} from "react-router-dom";
import {StateContext} from "../../state";

const TABLE_ROWS = 15;
type IProps = {
    sample: any;
    index: number;
    data: any,
    dataStateChanger: Function;
    drugItems: Option[];

}
const NMRTest: FC<IProps> = (props: IProps) => {
    const history = useHistory();
    const {dispatch} = useContext(StateContext);
    const {sample_id} = useParams<any>();
    const [name, setName] = useState<any>({firstName: '', lastName: ""});

    const [loading, setLoading] = useState<boolean>(true);

    const [staffFirstName, setStaffFirstName] = useState<string>('');
    const [staffLastName, setStaffLastName] = useState<string>('');
    const [drugs, setDrugs] = useState<any[]>([]);
    const [techNotes, setTechNotes] = useState<string>('');
    const [notes, setNotes] = useState<string>('');
    const [noDrugs, setNoDrugs] = useState<boolean>(false);

    // Computed values. Could be memoized if there is a performance gain needed.
    const percentSum: number = drugs.reduce((sum, curDrug) => sum + parseFloat(curDrug?.percent ?? 0), 0);
    const invalidPercentSum = percentSum > 100;

    const fillDrugs = (_drugs: any[] = []) => {
        const elements = _drugs.length;
        if (elements <= TABLE_ROWS) {
            for (let i = elements; i < TABLE_ROWS; i++) {
                _drugs.push({})
            }
            setLoading(false);

            setDrugs(_drugs);
        }
    }

    useEffect(() => {
        setNoDrugs(drugs.filter(d => d.name).length === 0);
    }, [drugs])

    useEffect(() => {

        fillDrugs();

    }, [sample_id, history])

    useEffect(() => {
        if (invalidPercentSum) {
            dispatch({
                type: "showAlert", payload: {
                    error: true,
                    message: 'Sum of drug percentages cannot exceed 100.',
                }
            });
        }
    }, [invalidPercentSum, dispatch])


    const getValue = useCallback((index: number, key: string) => {
        const drug = drugs[index];
        if (drug) {
            const value = drug[key];
            if (value) {
                return value;
            }
        }
        return "";
    }, [drugs])

    const selectChange = (v: any, index: number) => {
        setDrugs((old: any) => {
            if (!v) {
                old[index] = {}
                return [...old];
            }
            const x = old.find((d: any) => d.item === v);
            if (!x) {
                old[index].item = v;
                old[index].name = v ? v.text : '';
                setNoDrugs(false);
            }
            return [...old];
        })
    }

    const handlePaste = (event: any, index: number, setIsOpen: any) => {
        event.preventDefault();
        setIsOpen && setIsOpen(false);

        const paste: string = event.clipboardData.getData('text');
        const lines: string[] = paste.split("\n");

        setDrugs((old: any) => {
            for (let i = 0; i < lines.length; i++) {
                const idx = index + i;
                const line = lines[i];
                const cells = line.split("\t").map(cell => cell.trim());

                const searchKey = cells[0].toLowerCase().trim();
                if (searchKey.length < 1) continue

                let hasDrug = props.drugItems.find((d: any) => d.text.toLowerCase().trim() === searchKey)
                if (!hasDrug) {
                    hasDrug = props.drugItems.find((d: any) => d.alias?.toLowerCase().trim().split(";").map((a: string) => a.trim()).includes(searchKey))
                }

                if (hasDrug) {
                    selectChange(hasDrug, idx)
                } else {
                    old[idx].item = {id: -1, text: cells[0]};
                    old[idx].name = cells[0];
                }

                if (cells.length > 1) {
                    const value = parseFloat(cells[1]);
                    if (!isNaN(value)) {
                        old[idx].percent = value.toFixed(2);
                    }
                }
                if (cells.length > 2) {
                    const value = parseFloat(cells[2]);
                    if (!isNaN(value)) {
                        old[idx].weight = value.toFixed(4);
                    }
                }
            }
            return [...old];
        })
    }

    const doPaste = (event: any, key: string, drug: any, index: number) => {
        event.preventDefault();
        const paste: string = event.clipboardData.getData('text');
        const lines: string[] = paste.split("\n");

        setDrugs((old: any) => {
            for (let i = 0; i < lines.length; i++) {
                const idx = index + i;
                const line = lines[i];
                const cells = line.split("\t").map(cell => cell.trim());

                if (cells.length >= 1) {
                    const value = parseFloat(cells[0]);
                    if (!isNaN(value)) {
                        old[idx][key] = value.toFixed(key === "percent" ? 2 : 4);
                    }
                }

                if (cells.length >= 2 && key === "percent") {
                    const value = parseFloat(cells[1]);
                    if (!isNaN(value)) {
                        old[idx].weight = value.toFixed(4);
                    }
                }
            }
            return [...old];
        })
    }

    const fixDecimal = (key: string, index: number, fractionDigits: number = 2) => {
        setDrugs((old: any) => {
            if (old[index][key]) {
                old[index][key] = parseFloat(old[index][key]).toFixed(fractionDigits);
            }
            return [...old];
        })
    }
    useEffect(() => {
        const staffIsEmpty = (staffFirstName.length + staffLastName.length) === 0

        const hasError = staffIsEmpty || invalidPercentSum
        const data = {
            hasError,
            id: props.index,
            technology: "LCMS",
            data: {
                drugs,
                notes,
                techNotes,
                noDrugs,
                staffFirstName,
                staffLastName
            }

        }
        props.dataStateChanger(data)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staffLastName, staffFirstName, drugs, noDrugs, techNotes, notes]);


    useEffect(() => {
        setNotes(props.data.notes);
        setTechNotes(props.data.techNotes);
        const name = {firstName: props.data?.staffFirstName ?? "", lastName: props.data?.staffLastName ?? ""}

        setName(name)
        const draftDrugs: any[] = [];
        props.data?.drugs?.forEach((d: any, index: number) => {
            draftDrugs[index] = {
                item: d.drug ? {...d.drug, text: d.drug.name} : {id: -1, text: d.custom_drug},
                name: d.drug ? d.drug.name : d.custom_drug,
                weight: d.weight,
                percent: d.percent,
            }
        })

        fillDrugs(draftDrugs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (

        <>
            <div className="field">
                <h6>Substances Found In Sample</h6>
                <label className="label">Paste or enter the lab report into the field below.</label>
                <div className=" mb-2">
                    <table className="drugfoundtable">
                        <thead>
                        <tr style={{height: '47px'}}>
                            <th style={{width: '10px'}}></th>
                            <th style={{width: '29%'}}>Substance Name</th>
                            <th style={{width: '15%'}}>Quantity %</th>
                            <th style={{width: '8%'}}>Public</th>
                            <th>Alias</th>
                        </tr>
                        </thead>
                        <tbody>
                        {drugs.map((drug: any, index: number) => {
                            return (
                                <tr key={index}>
                                    <td className="rowindex">{index + 1}</td>
                                    <td className="entry">
                                        <Select
                                            items={props.drugItems}
                                            onChange={(v: any) => selectChange(v, index)}
                                            value={drug.item}
                                            mainClass="whiteback"
                                            nochevron
                                            canAdd
                                            onPaste={(event: any, setIsOpen: any) => handlePaste(event, index, setIsOpen)}
                                        />
                                    </td>
                                    <td className="entry input">
                                        <input type="number"
                                               step="0.001"
                                               max="100"
                                               className="entryinput"
                                               onWheel={(e: any) => e.target.blur()}
                                               onChange={e => {
                                                   setDrugs((old: any) => {
                                                       old[index].percent = e.target.value;
                                                       return [...old];
                                                   });
                                               }}
                                               value={noDrugs ? "" : getValue(index, 'percent')}
                                               onBlur={() => fixDecimal("percent", index)}
                                               onPaste={event => doPaste(event, 'percent', drug, index)}
                                        />
                                    </td>

                                    <td>
                                        {(drug.item && (drug.item.id === -1 || drug.item.report_to_public)) &&
                                            <Icons.IsPublic/>
                                        }
                                    </td>
                                    <td>
                                        {drug.item ? drug.item.alias ? drug.item.alias : "---" : ""}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <div style={{width: '250px'}}>
                    <Checkbox
                        checked={noDrugs}
                        onChange={(v: boolean) => {
                            if (v) {
                                fillDrugs([]);
                            }
                            setNoDrugs(v);
                        }}
                        label="No Substances Found"
                    />
                </div>
            </div>
            <div className="field w80">
                <label className="label">Tech Notes: (optional)</label>
                <textarea

                    style={{width: '100%'}}
                    value={techNotes}
                    onChange={e => setTechNotes(e.target.value)}
                />
            </div>
            <div className="field w80">
                <label className="label">Public Notes (optional):</label>
                <textarea
                    disabled={loading}
                    style={{width: '100%'}}
                    value={notes}
                    onChange={e => setNotes(e.target.value)}
                />
            </div>
            <div className="field w80">
                <label className="label">
                    Full name of staff that analyzed this sample using this technology: <span
                    className="required">*</span>
                </label>
                <Fullname name={name}
                          onName={(firstName: string, lastName: string) => {
                              setStaffFirstName(firstName);
                              setStaffLastName(lastName)
                          }}
                          onChange={(fullname: string) => {
                          }}/>
            </div>

        </>


    );
}

export default NMRTest;
